import {
  Box,
  Button,
  Card,
  CardContent,
  Hidden,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme, withTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MailOutlineSharpIcon from "@material-ui/icons/MailOutlineSharp";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import { useAuth } from "hooks/useAuth";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "react-multi-carousel/lib/styles.css";
import Footer from "../components/Footer/Footer";
// Components
import Header from "../components/Header/Header";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f5f5f5",
    minHeight: "100vh",
  },
  form: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "100%",
      color: "red",
    },
  },
  form_input: {
    color: theme.palette.text.primary,
  },
  paper: {},
  title: {
    padding: "24px 16px",
  },
  main_image: {
    width: "100%",
    height: "245px",
    position: "relative",
    padding: "0",
  },
  about_container: {
    padding: "2rem 0",
  },
  about_text: {
    fontFamily: "Quicksand",
    color: "#2a434e",
    fontSize: "14px",
    // textAlign: 'justify',
    padding: "18px",
  },
  primary: {
    color: theme.palette.primary.main,
  },
  about_q: {
    color: "#2a434e",
    fontSize: "16px",
    fontFamily: "Quicksand",
  },
  card_s: {
    height: "195px",
    width: "90%",
    margin: "3rem auto",
    borderRadius: "0 0 14px 14px",
  },
  card_media: {
    position: "relative",
    height: "144px",
  },
  card_content: {
    height: "51px",
    display: "flex",
    justifyContent: "center",
  },
  card_content_text: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  carousel: {
    marginTop: "36px",
  },
  card_item: {
    width: "158px",
    margin: "0",
    // padding: '0 20px'

    borderRadius: "0 0 11px 11px",
  },
  carousel_item: {
    height: "245px",
    width: "158px !important",
    padding: "0",
    marginLeft: "12px",
  },
  card_item_media: {
    height: "151px",
    position: "relative",
  },
  card_item_text: {
    height: "85px",
    display: "grid",
    gridTemplateRows: "1fr 1fr 1fr 2fr",
    padding: "9px",
  },
  card_item_label: {
    fontSize: "9px",
  },
  card_item_label_s: {
    fontSize: "8px",
    padding: "3px 0",
  },
  card_item_price: {
    fontSize: "11px",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  footer: {
    height: "136px",
    background: "blue",
    marginTop: "36px",
  },
  form_container: {
    width: "90%",
    margin: "2rem auto",
  },
  form_title: {
    color: "#2a434e",
    margin: "2rem 0",
  },
  form_button: {
    background: theme.palette.primary.main,
    color: "white",
    fontWeight: "bold",
    margin: "2rem 0",
  },
  register_label: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  fixed_bottom: {
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
  form_input: {
    margin: "1rem auto",
  },
  desktop_container: {
    width: "420px",
    height: "480px",
    background: "white",
    margin: "5rem auto",
  },
}));

// export async function getInititalProps(){
// }

function Login(props) {
  const auth = useAuth();
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const { register, errors, handleSubmit } = useForm({});
  const [open, setOpen] = React.useState(null);
  const [open2, setOpen2] = React.useState(null);
  const [open3, setOpen3] = React.useState(null);

  const onSubmit = (data) => {
    setDisabled(true);
    return auth
      .signIn(data)
      .then((user) => {
        // console.log('user',user);
        if (user !== undefined) {
          setOpen3(true);
          let localStorage = window.localStorage;
          let route = localStorage.getItem("route");
          localStorage.removeItem("route");
          route ? router.push(route) : router.push("/");
        } else {
          setDisabled(false);
          setOpen(true);
        }
      })
      .catch((err) => {
        setDisabled(false);
        if (err.code == "auth/wrong-password") setOpen(true);
        if (err.code == "auth/user-not-found") setOpen2(true);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Head>
        <title>Motofácil</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header></Header>
      {/* Desktop */}
      <Hidden smDown>
        <Box>
          <Box className={classes.desktop_container}>
            <Card variant="outlined">
              <CardContent className={classes.title}>
                <Typography variant="h6" color="primary">
                  Inicia sesión / Regístrate
                </Typography>
              </CardContent>
            </Card>
            <Box component="div" className={classes.form_container}>
              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  color="primary"
                  className={classes.form_input}
                  id="email"
                  name="email"
                  label="Correo electrónico"
                  // helperText="In41correct email"
                  error={
                    errors != undefined ? (errors.email ? true : false) : null
                  }
                  helperText={
                    errors != undefined
                      ? errors.email
                        ? errors.email.message
                        : ""
                      : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineSharpIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: "introduce tu email",
                    // pattern: /^\S+@\S+\.\S+$/
                    pattern: {
                      value: /^\S+@\S+\.\S+$/,
                      message: "No es un correo valido",
                    },
                  })}
                />
                <TextField
                  color="primary"
                  className={classes.form_input}
                  id="password"
                  name="password"
                  label="Contraseña"
                  type="password"
                  helperText={errors.password ? errors.password.message : ""}
                  error={errors.password ? true : false}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: "introduce tu contraseña",
                    minLength: {
                      value: 6,
                      message: "Al menos 6 caracteres",
                    },
                  })}
                />
                <Button
                  variant="contained"
                  className={classes.form_button}
                  disabled={disabled}
                  type="submit"
                >
                  Iniciar sesión
                </Button>
              </form>
              <Box display="flex" justifyContent="center" my={2}>
                <Typography>
                  ¿No tienes una cuenta?&nbsp;
                  <Box component="span" className={classes.register_label}>
                    <Link href="/registro">Regístrate aquí</Link>
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Hidden>
      {/* Mobile */}
      <Hidden mdUp>
        <Card>
          <CardContent className={classes.title}>
            <Typography variant="h6" color="primary">
              Inicia sesión / Regístrate
            </Typography>
          </CardContent>
        </Card>
        <Box component="div" className={classes.form_container}>
          {/* TODO: Create the action for the form */}
          {error?.message && (
            <div>
              <span>{error.message}</span>
            </div>
          )}
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <TextField
              color="primary"
              className={classes.form_input}
              id="email"
              name="email"
              label="Correo electrónico"
              // helperText="In41correct email"
              error={errors != undefined ? (errors.email ? true : false) : null}
              helperText={
                errors != undefined
                  ? errors.email
                    ? errors.email.message
                    : ""
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineSharpIcon />
                  </InputAdornment>
                ),
              }}
              inputRef={register({
                required: "introduce tu email",
                // pattern: /^\S+@\S+\.\S+$/
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: "No es un correo valido",
                },
              })}
            />
            <TextField
              color="primary"
              className={classes.form_input}
              id="password"
              name="password"
              label="Contraseña"
              type="password"
              helperText={errors.password ? errors.password.message : ""}
              error={errors.password ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              inputRef={register({
                required: "introduce tu contraseña",
                minLength: {
                  value: 6,
                  message: "Al menos 6 caracteres",
                },
              })}
            />
            <Button
              variant="contained"
              className={classes.form_button}
              disabled={disabled}
              type="submit"
            >
              Iniciar sesión
            </Button>
          </form>
          <Box display="flex" justifyContent="center" my={2}>
            <Typography>
              ¿No tienes una cuenta? &nbsp;
              <Box component="span" className={classes.register_label}>
                <Link href="/registro">Regístrate aquí</Link>
              </Box>
            </Typography>
          </Box>
        </Box>
      </Hidden>
      <Box position="absolute" bottom="0" width="100%">
        <Footer></Footer>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        message="No ha podido iniciar sesión, Revise sus credenciales"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open2}
        autoHideDuration={10000}
        onClose={handleClose}
        message="Usuario no encontrado"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open3}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Iniciando sesión"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default withTheme(Login);
